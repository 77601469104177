<template>
    <div>
        <el-dialog :title="row.id ? '新增商家收款账户' : '编辑商家收款账户'" :visible.sync="centerDialogVisible" width="500px">
            <el-form :model="form" style="padding: 0px 30px 0 10px" label-width="120px" class="demo-form">
                <el-form-item label="商家名称" prop="businessName">
                    <el-input v-model="form.businessName" @focus="focus"></el-input>
                </el-form-item>
                <el-form-item label="商家账号" prop="businessAccount">
                    <el-input v-model="form.businessAccount" disabled></el-input>
                </el-form-item>
                <el-form-item label="收款银行" prop="region">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="收款卡号">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="持卡人姓名" prop="delivery">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="银行预留手机号" prop="type">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
                <el-button size="medium" type="info" @click="centerDialogVisible = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="save">保 存</el-button>
            </span>
        </el-dialog>
        <selectBusiness :selectDialog="selectDialog" @selectRow="selectRow" @closeDialog="closeDialog"></selectBusiness>
    </div>
</template>

<script>
import selectBusiness from "@/components/selectBusiness.vue";
export default {
    components:{
        selectBusiness
    },
    data() {
        return {
            centerDialogVisible: false,
            selectDialog: false,
            form: {
                businessName: '',
                businessAccount: '',
                name1: '',
                aa2: '',
                aa3: '',
                aa4: ''
            },
            dialogTitle: '新增商家收款账户',
            row: {}
        };
    },
    created() {

    },
    methods: {
        init(row = {}) {
            console.log('init',row)
            this.centerDialogVisible = true
            this.row = row
        },
        focus(){
            console.log('focus')
            this.selectDialog = true
        },
        selectRow(val){
            console.log('获取商家',val)
            this.form.businessName = val.businessName
            this.form.businessAccount = val.businessAccount
            this.selectDialog = false
        },
        closeDialog(){
            this.selectDialog = false
        },
        save(){
            this.centerDialogVisible = false
        },
    }
};
</script>