<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="商家名称" prop="businessName">
              <el-input
                v-model="form.businessName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商家账号" prop="businessAccount">
              <el-input
                v-model="form.businessAccount"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="交易时间" prop="industryType">
              <el-date-picker @change="change" style="width: 100%;" v-model="valuechange" type="daterange"
                start-placeholder="开始日期" end-placeholder="结束日期" />
            </el-form-item>
          </el-col> -->
          <el-col
            :span="8"
            style="
              display: flex;
              justify-content: flex-end;
              padding-right: 18px;
            "
          >
            <el-button @click="reset" icon="el-icon-refresh-left"
              >重置</el-button
            >
            <el-button type="primary" @click="search" icon="el-icon-search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="tab">
      <div class="add" style="display: flex; justify-content: space-between">
        <el-button type="primary" @click="openAdd">新增商家收款账户</el-button>
        <el-button type="primary" @click="added">导出</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        show-summary
        height="calc(100vh - 380px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        <el-table-column label="序号" width="60" type="index" align="center">
        </el-table-column>
        <el-table-column
          prop="businessName"
          label="商家名称"
          show-overflow-tooltip
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="accountName"
          label="商家账号"
          show-overflow-tooltip
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="bankName"
          label="收款银行"
          show-overflow-tooltip
          min-width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="cardNumber"
          label="收款卡号"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payoutsTotalNum"
          label="持卡人姓名"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="linkPhone" label="银行预留手机号" align="center">
        </el-table-column>
        <el-table-column
          prop=" "
          label="操作"
          width="180"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="openEdit(scope.row.id)"
              >编辑</el-button
            > -->
            <el-button
              type="text"
              style="color: #f56c6c"
              @click="deta(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <add-edit ref="addEditRef"></add-edit>
  </div>
</template>
<script>
import addEdit from "./merchantcollection/merchantcollectionAdd.vue"; // 新增储存卡
export default {
  components: { addEdit },
  data() {
    return {
      showDialog: false,
      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 50,
        pageCount: 1,
        currPage: 1,
        condition: {
          name: "",
          startDate: "",
          endDate: "",
          userType: 1,
        },
      },
      valuechange: "",
      form: {},
      tapshow: 0,
      options: [
        {
          value: "1",
          label: "店长",
        },
        {
          value: "2",
          label: "员工",
        },
      ],
      radio: "1",
      from: {},
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    change() {
      console.log(this.valuechange);
      this.queryInfo.condition.startDate = this.newDate(this.valuechange[0]);
      this.queryInfo.condition.endDate = this.newDate(this.valuechange[1]);
    },
    newDate(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/cards/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage;
          that.tableData = response.data.data.data;
        }
      });
    },
    // 新增商家收款账户-打开弹窗
    openAdd() {
      console.log(this.$refs.addEditRef, "this.$refs.addEditRef");
      this.$refs.addEditRef.init();
    },
    openEdit() {
      this.$refs.addEditRef.init();
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    search() {
      this.queryInfo.condition = this.form;
      this.queryInfo.currPage = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.queryInfo.condition = {};
      this.queryInfo.currPage = 1;
      this.getList();
    },
    deta(id) {
      this.showDialog = true;
    },
    added() {
      this.showDialog = true;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 20px 20px;

    .search-top {
      width: 1540px;
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
      }

      .active {
        border-bottom: 2px solid red;
      }
    }
  }

  .finance {
    height: 132px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    justify-content: space-around;

    .fin-box {
      display: flex;

      .fin-li {
        margin-left: 15px;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
  }

  .dia {
    .but {
      display: flex;
      justify-content: space-between;
      padding: 30px 200px;
    }

    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }
  }
}
</style>
