<template>
  <div class="select-content">
    <el-dialog title="选择商家" :visible.sync="visibleDialog" @before-close="confuse" width="600px" height="400px">
      <el-form label-width="80px" label-height="40px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商家名称：" prop="businessName">
            <el-input v-model="businessName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex; justify-content: right;">
            <el-button type="primary" @click="searchsj" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="dia">
        <el-table :data="restaurants" style="width: 100%" height="calc(100vh - 480px)"
          v-loading="loading"
          highlight-current-row
          @current-change="handleCurrentRowChange" border :header-cell-style="{
              'background-color': '#f2f2f2',
              'text-align': 'center',
              border: '1px solid #ddd',
          }">
            <el-table-column label="" width="40" align="center">
              <template slot-scope="scope">
                <el-radio class="radio" v-model="templateSelection" :label="scope.$index"><span></span></el-radio>
              </template>
            </el-table-column>
            <el-table-column type="index" label="序号" width="50" align="center">
            </el-table-column>
            <el-table-column prop="businessName" label="商家名称" align="center">
            </el-table-column>
            <el-table-column prop="creatTime" label="创建时间" align="center">
            </el-table-column>
          </el-table>
        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="confuse">取消</el-button>
          <el-button type="primary" @click="confirm">确定 </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "selectBusiness",
  props:{
    selectDialog:{
        type:Boolean,
        default:false
    },
  },
  watch:{
    selectDialog(newVal){
      console.log('selectDialog----',newVal,!!newVal)
      this.visibleDialog = !!newVal
    },
  },
  data() {
    return {
      visibleDialog: false,
      loading: false,
      businessName: '',
      templateSelection:'',
      restaurants:[],
    };
  },
  created() {
  },
  mounted() {
    this.init()
  },
  methods: {
    //初始化函数
    init (e) {
      console.log('init----')
        const that = this
        that.loading = true
        that.$http.post('/business/queryBusinessList',{}).then(response => {
          that.loading = false
          that.restaurants = response.data.data
        })
    },
    searchsj(){
      var that = this;
      that.loading = true
      that.$http.post('/business/queryBusinessList',{
        businessName:that.businessName
      }).then(response => {
        that.loading = false
        that.restaurants = response.data.data
      })
    },
    handleCurrentRowChange(row) {
      this.currentRow = row;
      this.templateSelection = this.restaurants.indexOf(row)
    },
    confirm(val){
      this.$emit('selectRow',this.currentRow)
    },
    confuse(){
      this.$emit('closeDialog',false)
    },
  },
}
</script>

<style scoped lang="scss">
.select-content{
    display: flex;
    display: -webkit-flex;
    .radio{
      margin-right: 0;
      /deep/.el-radio__label{
        padding-left: 0;
      }
    }
}
</style>  